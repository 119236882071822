.nav {
  padding: 0;
  margin: 0;
  text-align: right;
}

.nav-item {
  display: inline-block;
}

.nav-item > a {
  display: block;
  padding: 24px;
  color: white;
  text-decoration: none;
}

.nav-item > a:hover {
  color: #2196f3;
}
